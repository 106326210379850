import React from "react"
import Empty from "../../../../shared/Empty/Empty";
import Table, { TableAction, TableBody, TableHeading } from "../../../../shared/Table/Table";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../store/store";
import { setShowCoursePriceModal, singleCoursePrice } from "../../../../store/features/AdminPanel/CoursePrice/CoursesPriceSlice";

const CoursePriceContent = () => {
    const dispatch = useAppDispatch();
    const handleEdit = (data:any)=>{
        dispatch(singleCoursePrice(data));
        dispatch(setShowCoursePriceModal(true));
    }
    const coursePriceState = useAppSelector((store:RootStore)=>store.CoursesPriceSlice)
  return (
    <div className="tenant__table__wrapper">
    <div className="user__table__section">
      <Table
        heading={
          <TableHeading>
            <th>Course Name</th>
            <th>Course Price($)</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {coursePriceState?.courses_price?.map(
              (item: any) => {
                return (
                  <tr >
                    <td>{item.name}</td>
                    <td>${item.price}</td>
                    <TableAction  hideDelete onEdit={()=>handleEdit(item)} />
                  </tr>
                );
              }
            )}
        </TableBody>
      </Table>
      {!coursePriceState.loading &&
        coursePriceState?.courses_price?.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty emptyText="It seems empty." custombg={true} />
          </div>
        )}
    </div>

  </div>
  )
}

export default CoursePriceContent
