import React from "react"
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";

import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import WeeklyLearningHeader from "../../../components/AdminPanel/WeeklyLearning/Header/WeeklyLearningHeader";
import WeeklyLearningTable from "../../../components/AdminPanel/WeeklyLearning/Table/WeeklyLearningTable";


const ProductPageWeeklyLearning = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
        <WeeklyLearningHeader from="product_page" />
        <WeeklyLearningTable />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  )
}

export default ProductPageWeeklyLearning
