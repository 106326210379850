import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { REQUIRED__TOASTER } from "../../../../helpers/utils/TosterError";

export const loadCoursePrice = createAsyncThunk(
  "loadCoursePrice",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(
        `${dynamic_url}/certified-course-pricing`
      );
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addorUpdatecoursePrice = createAsyncThunk(
  "addorUpdatecoursePrice",
  async ({ data }: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/certified-course-pricing/${data.id}`,
        { price: data?.price }
      );

      if (res.data.success) {
        toast.success("Price updated successfully.");
      }

      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
