import SearchBar from "../../../../shared/SearchBar/SearchBar";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";


const CertificateForTeamHeader = (props: any) => {
  const { searchText, setSearchText } = props;

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Certificate for Team</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
      </div>
    </div>
  );
};


export default CertificateForTeamHeader
