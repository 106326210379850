import React from "react"
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout"
import BoxShadow from "../../../shared/BoxShadow/BoxShadow"
import CoursesPriceHeader from "../../../components/AdminPanel/CoursesPrice/CoursesPriceHeader/CoursesPriceHeader"
import CoursePriceContent from "../../../components/AdminPanel/CoursesPrice/CoursesPriceContent/CoursePriceContent"

const CoursesPricePage = () => {
  return (
    <AdminPanelLayout>
    <BoxShadow>
      <div className="mt-7">
        <CoursesPriceHeader />
        <CoursePriceContent />
      </div>
    </BoxShadow>
  </AdminPanelLayout>
  )
}

export default CoursesPricePage
