import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import CertificateForTeamTable from "../../../components/AdminPanel/CertificateForTeam/CertificateForTeamTable/CertificateForTeamTable";

const CertificateForTeam = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
          <CertificateForTeamTable/>
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default CertificateForTeam;
