import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";

export const getCompanies = createAsyncThunk(
  "getCompanies",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/get-companies`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyById = createAsyncThunk(
  "companyDetails",
  async ({ id }: { id: string }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/company-details/${id}`
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendTenantInvitation = createAsyncThunk(
  "resendTenantInvitation",
  async ({ id }: { id: string }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/resend-tenant-invitation`,
        {
          tenantId: id,
        }
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCompaniesForCertificateForTeam = createAsyncThunk(
  "getCompaniesForCertificateForTeam",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/team-membership-with-courses`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
