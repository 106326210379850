import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addorUpdatecoursePrice, loadCoursePrice } from "./CoursesPriceApi";

const initialState: any = {
  loading: false,
  req_success: false,
  courses_price: [],
  single_course_price:[],
  showCoursePriceModal: false,
};

const CoursesPriceSlice = createSlice({
  name: "CoursesPrice",
  initialState,
  reducers: {
    singleCoursePrice:(state,{payload}:PayloadAction<any>)=>{
        state.single_course_price = state.courses_price.filter((item:any)=>item?.id === payload?.id);
        // state.showCoursePriceModal = true;

    },
    setShowCoursePriceModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showCoursePriceModal = payload;
    },
  },
  extraReducers: (builder) => {
    // LOAD COURSE PRICE
    builder.addCase(loadCoursePrice.pending, (state) => {
      state.loading = true;
    //   state.req_success = false;
    });
    builder.addCase(
      loadCoursePrice.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = false;
        state.courses_price = payload?.data;
      }
    );
    builder.addCase(loadCoursePrice.rejected, (state) => {
      state.loading = false;
      state.req_success = false;
    });

    // ADD OR UPDATE COURSES PRICE
    builder.addCase(addorUpdatecoursePrice.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      addorUpdatecoursePrice.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
    
        // Find the index of the course to update
        const updatedCourseIndex = state.courses_price.findIndex(
          (course: any) => course.id === payload.price.id
        );
    
        // Update the course in the courses_price array
        if (updatedCourseIndex !== -1) {
          state.courses_price[updatedCourseIndex] = {
            ...state.courses_price[updatedCourseIndex],
            ...payload.price, // Update the specific fields
          };
        }
      }
    );
    
    builder.addCase(addorUpdatecoursePrice.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setShowCoursePriceModal,singleCoursePrice } = CoursesPriceSlice.actions;
export default CoursesPriceSlice.reducer;
