import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";


const validationSchema = yup.object().shape({
  price: yup
    .number()
    .typeError(REQUIRED_VALIDATION("Price")) 
    .required(REQUIRED_VALIDATION("Price"))
    .positive("Price must be a positive number") 
    .integer("Price must be an integer"), 
});
  
  export const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  