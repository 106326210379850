/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

import { formOptions } from "./coursesPriceHelper";
import {
  addorUpdatecoursePrice,
  loadCoursePrice,
} from "../../../../store/features/AdminPanel/CoursePrice/CoursesPriceApi";
import { setShowCoursePriceModal } from "../../../../store/features/AdminPanel/CoursePrice/CoursesPriceSlice";

const CoursesPriceHeader = (props: any) => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();
  const coursePriceState = useAppSelector(
    (store: RootStore) => store.CoursesPriceSlice
  );

  useEffect(() => {
    dispatch(loadCoursePrice());
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    const data = { ...values, id: coursePriceState.single_course_price[0].id };
    dispatch(addorUpdatecoursePrice({ data }));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const closeModalOnCrossIconClick = () => {
    dispatch(setShowCoursePriceModal(false));
    setShowModal(false);
  };

  useEffect(() => {
    if (coursePriceState?.showCoursePriceModal) {
      setShowModal(true);
      setValue("price", coursePriceState.single_course_price[0].price);
    }
  }, [coursePriceState?.showCoursePriceModal]);

  useEffect(() => {
    if (!coursePriceState?.loading && coursePriceState?.req_success) {
      closeModalOnCrossIconClick()
    }
  }, [coursePriceState?.loading, !coursePriceState?.req_success]);

 
  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Courses Price</TableHeadingTitle>
      </div>

      <Modal
        show={showModal}
        setShow={setShowModal}
        onClick={closeModalOnCrossIconClick}
      >
        <ModalHeader title="Update Course Price" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form_row">
              <TextField
                type="number"
                label={`Price of ${coursePriceState?.single_course_price[0]?.name}($)`}
                name="price"
                register={register}
                error={errors?.price}
              />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton
                text="Update"
                type="submit"
                loading={
                  coursePriceState?.loading && !coursePriceState?.req_success
                }
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CoursesPriceHeader;
