import React, { useEffect, useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import CertificateForTeamHeader from "../CertificateForTeamHeader/CertificateForTeamHeader";
import Table, { TableBody, TableHeading } from "../../../../shared/Table/Table";
import Empty from "../../../../shared/Empty/Empty";
import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import "./index.scss";
import { getCompaniesForCertificateForTeam } from "../../../../store/features/AdminPanel/TenantTeams/TenantTeamApi";
import {
  companyForCertificateForPaginate,
  searchCompanyForCertifiacteForTeamTable,
} from "../../../../store/features/AdminPanel/TenantTeams/TenantTeamSlice";

const CertificateForTeamTable = () => {
  const [searchText, setSearchText] = useState("");

  const dispatch = useAppDispatch();
  const tenantTeamState: any = useAppSelector((store: RootStore) => {
    return store.TenantTeamSlice;
  });

  useEffect(() => {
    dispatch(getCompaniesForCertificateForTeam());
  }, []);

  useEffect(() => {
    dispatch(searchCompanyForCertifiacteForTeamTable(searchText));
  }, [searchText]);

  const handlePagination = (num: number) => {
    dispatch(companyForCertificateForPaginate(num));
  };

    // Sort data by latest created_at date
    const sortedData = tenantTeamState?.company_certificate_for_team?.slice().sort(
      (a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

  return (
    <div>
      <CertificateForTeamHeader
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className="tenant__table__wrapper">
        <div className="user__table__section">
          <Table
            heading={
              <TableHeading>
                <th>Company Name</th>
                <th>Company Email</th>
                <th className="no__wrap">Company’s Phone Number</th>
                <th>Course Access</th>
                <th>Date</th>
                <th className="no__wrap">Team count</th>
              </TableHeading>
            }
          >
            <TableBody>
              {!tenantTeamState.loading.get_all &&
                tenantTeamState.req_success.get_all &&
                sortedData?.map(
                  (item: any) => {
                    return (
                      <tr key={item.id}>
                        <td>{item?.company_name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td className="c__list">
                          <ul>
                            {item?.formatted_courses?.map((course: string) => (
                              <li>{course}</li>
                            ))}
                          </ul>
                        </td>
                        <td style={{whiteSpace:"nowrap"}}>  {new Date(item?.created_at).toLocaleDateString()}</td>
                        <td className="team__count">{item?.no_of_employees}</td>
                      </tr>
                    );
                  }
                )}
            </TableBody>
          </Table>
          {!tenantTeamState.loading.get_all &&
            tenantTeamState?.company_certificate_for_team?.length === 0 && (
              <div className="mt-5 handle__empty__container__div">
                <Empty emptyText="It seems empty." custombg={true} />
              </div>
            )}
        </div>
        {/* <Empty emptyText="It seems empty" custombg/> */}
        <div className="pagination__section">
          <PaginateButton
            paginate={handlePagination}
            totalRows={
              tenantTeamState?.all_company_certificate_for_team?.length
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CertificateForTeamTable;
