/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card } from "../../../shared/Card/Card";
import "./course.scss";
import onlineCourse from "../../../assets/icons/OnlineCourse.jpg";
import badgeIcon from "../../../assets/icons/badge-icon.png";
import AgilleCertifiedbadge from "./AgileCertifiedBadge/AgilleCertifiedbadge";
import Purchesbadge from "./AgileCertifiedBadge/PurchesBadge";
import { renderDynamicUrlFromComponentLevelState } from "../../../helpers/utils/urlHelper";
import { RootStore, useAppDispatch, useAppSelector } from "../../../store/store";
import axiosInstance from "../../../apiConfigs/axiosInstance";
import { TACPrimaryButton } from "../../../shared/Buttons/Buttons";
import { enrollToThinkificCourse, loginToThinkificAndRedirect } from "../../../store/features/Thinkific/ThikificApi";

const Course = (props: any) => {
  const { course, onClick, showTotalNumber } = props;
  const [loadingCourseid, setLoadingCourseid] = useState(0);
  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
    const dispatch = useAppDispatch();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const thinkificState: any = useAppSelector((store: RootStore) => {
    return store.ThinkificSlice;
  });
  const coursesState: any = useAppSelector((store: RootStore) => {
    return store.ShowCourseSlice;
  });

  // console.log(authState.tac_user?.plan,authState.tac_user?.plan !=="paid");
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const createStripeSession = async (e: any, courseId: any) => {
    e.stopPropagation();
    console.log(e);
    setLoading(true);
    try {
      const dynamic_url = renderDynamicUrlFromComponentLevelState(configState);
      const res = await axiosInstance.post(`${dynamic_url}/checkout-session`, {
        billing_type: "one-time",
        coupon: null,
        courseId: Number(courseId),
      });
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      console.error("Error creating Stripe session:", error);
    } finally {
      setLoading(false);
    }
  };

    const startLearning = (e:any,isInmobile: boolean) => {
      e.stopPropagation();
      setLoadingCourseid(course.course_id);
      dispatch(
        enrollToThinkificCourse({
          courseId: course.course_id,
        })
      ).then((res) => {
        dispatch(
          loginToThinkificAndRedirect({
            slug: course.slug,
          })
        ).then((res: any) => {
          if (res.payload) {
            if (isInmobile) {
              window.location.href = res.payload.url;
            } else {
              window.open(res.payload.url, "_blank");
            }
          }
        });
      });
    };

    const redirectToEnrollPage = (e:any,enrollLink: string, isInmobile: boolean) => {
      e.stopPropagation();
      if (isInmobile) {
        window.location.href = enrollLink;
      } else {
        window.open(enrollLink, "_blank");
      }
    }


    useEffect(() => {
      if (thinkificState.login_req_success) {
        setLoadingCourseid(0);
      }
    }, [thinkificState.login_req_success]);


 useEffect(() => {
    if (coursesState.enroll_success) {
      window.location.href = `${themeState.configs.site_url}/login/${authState.tac_user.email}/${course.slug}`;
    }
  }, [coursesState?.enroll_success]);

  console.log(course,coursesState);
  return (
    <div className="course_section" onClick={onClick}>
      <Card>
      {location.pathname === "/all-premium-courses" && (
              <div
                className="text__left"
              >
                <AgilleCertifiedbadge text="Agile Certified" />
              </div>
            )}
        <div className="course">
          <div className="image__wrapper">
            {location.pathname === "/all-premium-courses" && (
              <img src={badgeIcon} alt="" className="premium__image" />
            )}

            {showTotalNumber && (
              <img
                src={
                  course.bundle_card_image_url?.startsWith("https", 0)
                    ? course?.bundle_card_image_url
                    : onlineCourse
                }
                alt="."
                className="course__thumbnail"
              />
            )}
            {!showTotalNumber && (
              <img
                src={
                  course.course_card_image_url?.startsWith("https", 0)
                    ? course?.course_card_image_url
                    : onlineCourse
                }
                alt="."
                className="course__thumbnail"
              />
            )}
          </div>

          <div
            className={`course__info ${
              location.pathname === "/all-premium-courses" &&
              "course__info__wrapper"
            }`}
          >
            <div className="name__tag">
            <span className={`course__title ${location.pathname === "/all-premium-courses" && "title__height"}`}>
            {course?.name?.length > 80 
            ? `${course?.name?.slice(0, 80)}...`
            : course?.name}
            </span>
              {course?.purchased ||
                (course?.individual_course_purchased && (
                  <div className="badge__wrapper">
                    <Purchesbadge text="Purchased" />
                  </div>
                ))}
      
            </div>

            <span className="course_secondary__title">
              {!showTotalNumber && `Duration ${course.custom_duration}`}
              {showTotalNumber && ` ${course.path_courses.length} Courses`}
            </span>

            <span className="course__description">  {course?.description?.length > 100 
    ? `${course?.description?.slice(0, 100)}...`
    : course?.description}</span>

            {location.pathname === "/all-premium-courses" && (
              <>
                {/* showing buttons for premium courses starts here */}
                {course.is_premium_course &&
                  authState.tac_user?.enrolled_certified_courses?.includes(
                    course.course_id
                  ) &&
                  course.percentage_completed === 0 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Start Course"
                        width="tac__color__btn__color__handle__InLg fit__width"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              course.course_id)
                        }
                        onClick={(e:any) => startLearning(e,false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Start Course"
                        width="tac__color__btn__color__handle__Insm fit__width"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              course.course_id)
                        }
                        onClick={(e:any) => startLearning(e,true)}
                      />
                    </>
                  )}
                {course.is_premium_course &&
                  !authState.tac_user?.enrolled_certified_courses?.includes(
                    course.course_id
                  ) &&
                  course.percentage_completed === 0 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Enroll Now"
                        width="tac__color__btn__color__handle__InLg fit__width"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              course.course_id)
                        }
                        onClick={(e:any) => redirectToEnrollPage(e,course.payment_url, false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Enroll Now"
                        width="tac__color__btn__color__handle__Insm fit__width"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              course.course_id)
                        }
                        onClick={(e:any) => redirectToEnrollPage(e,course.payment_url, true)}
                      />
                    </>
                  )}
                {/* showing buttons for premium courses ends here */}
                {course.percentage_completed > 0 &&
                  course.percentage_completed < 100 && (
                    <>
                      <TACPrimaryButton
                        type="button"
                        text="Resume Course"
                        width="tac__color__btn__color__handle__InLg fit__width"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              course.course_id)
                        }
                        onClick={(e:any) => startLearning(e,false)}
                      />
                      <TACPrimaryButton
                        type="button"
                        text="Resume Course"
                        width="tac__color__btn__color__handle__Insm fit__width"
                        loading={
                          coursesState.enroll_loading ||
                          (thinkificState.loading &&
                            loadingCourseid ===
                              course.course_id)
                        }
                        onClick={(e:any) => startLearning(e,true)}
                      />
                    </>
                  )}

                {Math.floor(course.percentage_completed) ===
                  100 && (
                  <>
                    <TACPrimaryButton
                      type="button"
                      text="Course Completed"
                      width="tac__color__btn__color__handle__InLg fit__width"
                      loading={
                        coursesState.enroll_loading ||
                        (thinkificState.loading &&
                          loadingCourseid ===
                            course.course_id)
                      }
                      onClick={(e:any) => startLearning(e,false)}
                    />
                    <TACPrimaryButton
                      type="button"
                      text="Course Completed"
                      width="tac__color__btn__color__handle__Insm fit__width"
                      loading={
                        coursesState.enroll_loading ||
                        (thinkificState.loading &&
                          loadingCourseid ===
                            course.course_id)
                      }
                      onClick={(e:any) => startLearning(e,true)}
                    />
                  </>
                )}
              </>
              
            )}
      
            {(authState.tac_user?.plan !== "paid" && (!course?.individual_course_purchased  &&
              course?.individual_course_price !== false &&
              location.pathname === "/list-all-courses" )) && (
                <div className="premium__paid__info">
                  <button
                    type="button"
                    className="buy__now__btn"
                    onClick={(e: any) =>
                      createStripeSession(e, course?.course_id)
                    }
                  >
                    {loading ? <div className="spinner mr-1" /> : "Buy Now"}
                  </button>
                  <p className="premium__price">
                    $ {course?.individual_course_price}
                  </p>
                </div>
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Course;
