/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./faqheader.scss";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  addFaqs,
  loadFaqs,
} from "../../../../store/features/UserDashboard/Faq/FaqApi";
import { searchFaqs } from "../../../../store/features/UserDashboard/Faq/FaqSlice";
import SelectDropDown from "../../../../shared/SelectDropDown/SelectDropDown";
import { FAQFilterForTACOnly, FaqArray, formOptions } from "./helper";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const FaqHeader = (props: any) => {
  const {from} = props;
  // console.log(from);

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  let data;
  if (from==="landing_page" && configState.configs?.membership_enabled === Domain.TAA) {
    data = [{ id: 4, name: "Landing Page", value: "landing_page" }];
  }
  else if (configState.configs?.membership_enabled === Domain.TAA && from ==="product_page"){
    data = FaqArray.filter(item => item.name !== "Landing Page");
  }
   else if (configState.configs?.membership_enabled === Domain.TAC && from ==="product_page") {
      // Exclude "Landing Page" from TAC options
      data = FAQFilterForTACOnly.filter(item => item.name !== "Landing Page");
    } 
    else if (configState.configs?.membership_enabled === Domain.TAC && from ==="landing_page"){
          // Show only "Landing Page"
        data = [{ id: 4, name: "Landing Page", value: "landing_page" }];
    }
    else{
      data = [{ id: 4, name: "Landing Page", value: "landing_page" }];
    }


  const [showModal, setShowModal] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState<Ivalue>(
    configState.configs?.membership_enabled === Domain.TAC && from ==="product_page" ? data[0] : data[0]
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(searchFaqs(searchText));
  }, [searchText]);

  useEffect(() => {
    dispatch(loadFaqs(selectedValue.value));
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    const data = { ...values };
    data.for = selectedValue.value;
    dispatch(addFaqs(data));
    setShowModal(false);
    reset();
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  interface Ivalue {
    id: number;
    name: string;
    value: string;
  }

  const handleOption = (data: Ivalue) => {
    setSelectedValue(data);
    dispatch(loadFaqs(data.value));
  };
  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>FAQ listing For</TableHeadingTitle>
        <div className="dropdown__holder">
          <SelectDropDown
            data={
              data
            }
            selectedValue={selectedValue.name}
            handleOption={handleOption}
          />
        </div>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal}>
        <ModalHeader title="FAQ" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form_row">
              <TextField
                type="text"
                label="Question"
                name="question"
                register={register}
                error={errors?.question}
              />
            </div>

            <div className="form_row">
              <TextArea
                type="text"
                label="Answer"
                name="answer"
                register={register}
                error={errors?.answer}
              />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton text="Add" type="submit" loading={false} />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FaqHeader;
